/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Lisbonne"), React.createElement("p", null, "En partant de la place du Commerce au bord du Tage, Lisbonne se présente en quatre grandes zones où l’on peut se loger :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/baixa.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Baixa et Chiado"), ", quartiers touristiques et commerçants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/bairroalto.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bairro Alto"), ", quartier jeune très animé le soir, à l’ouest."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/alfama.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Alfama"), " et le ", React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/castelo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "château de Saint-Georges"), ", quartier le plus ancien, à l’est."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/belem.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Belém"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/alcantara.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "les quais d’Alcântara"), " réhabilités, situés le long du Tage, en aval, mais aussi le quartier moderne du ", React.createElement("a", {
    href: "https://www.booking.com/district/pt/lisbon/parque-das-nacoes.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Parc des Nations"), ", en amont, construit à l’occasion de l’Expo’98."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
